.undroppable .list-group-item {
	opacity: 0.75;
	background-color: rgb(247, 185, 71) !important;
}

.dragging .list-group-item {
	opacity: 0.75;
	background-color: rgb(171, 247, 171) !important;
}

.drag-item .list-group-item {
	transition: background-color 250ms ease ;
}

.drag-item .list-group-item {
	background-color: none;
}

