@font-face {
    font-family: 'Nasalization';
    src: 
         url('/static/fonts/nasalization.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

html, body, #root, .app {
    height: 100%;
    overflow-x: hidden;
}

body {
    background: #1896c6 no-repeat center center fixed; /* Old browsers */
    background: -moz-linear-gradient(top, #1896c6 0%, #005b7e 100%) no-repeat center center fixed; /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #1896c6 0%,#005b7e 100%) no-repeat center center fixed; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #1896c6 0%,#005b7e 100%) no-repeat center center fixed; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1896c6', endColorstr='#005b7e',GradientType=0 ) no-repeat center center fixed; /* IE6-9 */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.cc-font {
    font-family: 'Nasalization';
}

#page-content table {
    padding-bottom : 20px;
    border-collapse : collapse;
    display : table;
    overflow-x : auto;
    font-weight: 300;
    width:100%;
}
#page-content table tr:nth-child(odd) {
    background : #D5D5D5;
}
#page-content th {
    background : #1896c6;
    color : #ffffff;
    border : #1896c6 solid 1px;
    padding : 10px;
    font-weight: 300;
}
#page-content td {
    border : #F0F0F0 solid 1px;
    padding : 10px;
}

.fa-add-extend {
    top: -10px;
    position: relative;
    left: -5px;
    font-size: 0.5rem;
}

.user-icon {
    padding: 6px 8px;
}

.page-amount {
    max-width: 60px;
}

.large-status {
    vertical-align: middle;
    text-align: center;
    padding: 24px;
}

.large-status button {
    padding: 24px;
    margin: 0 auto;
    width: 100%;
    font-size: 1.5rem !important;

}