
.page {
    transition: 150ms cubic-bezier(0.5, .1, .5, 1);
    max-width: 100%;
    height: 100%;
}

.page-wrap {
    position: absolute;
    transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.loading-container {
    text-align: center;
    margin: 0 auto;
    padding: 32px 0;
}

.not-found-container {
    text-align: center;
    margin: 0 auto;
    padding: 32px 0;
}

.content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
    height: 100%;
    box-shadow: #0000001f 1px 5px 10px;
    min-height: 100vh;
    padding-bottom: 20px;
    top: 32px;
    position: relative;
    padding: 24px;
    margin-bottom: 32px;
    background: #ffffff;
}

.edit-page {
    margin-top:-30px;
}