.cc-title {
    color: #1896c6;
    margin: 0px 0 10px 0;
    padding: 0 24px;
}

.cc-subtitle {
    color: #1896c6;
    font-size: 12px;
    padding: 0 20px;
    /* text-align: center; */
    margin: 0px;
    margin-top: -12px;
}

.header {
    box-shadow: #0000001f 1px 5px 10px;
    background: #ffffff;
}

.info-bar {
    margin: 0 auto !important;
    max-width: 1200px;
}

.header-options {
    min-height: 70px;
    width: 100%;
    padding: 12px 16px;
    height: auto;
}

.logo {
    max-width:275px;
    margin: 0 auto;
}


.pulsating {
    display: block;
    cursor: pointer;
    animation: pulsating 2s infinite;
  }
  .pulsating:hover {
    animation: none;
  }
  
  @-webkit-keyframes pulsating {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.4);;
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  @keyframes pulsating {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.4);;
      box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.4);;
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }