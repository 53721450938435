.home-page {
	width: 100%;
	min-height: 100vh;
	background: #ffffff;
}

.home-page img {
	width: 100vw !important;
	vertical-align: text-top;
}

.home-page table, .home-page td, .home-page tr {
	max-width: 1250px !important;
	margin: 0 auto;
	border: 0px solid black;
	text-align: center;
}

