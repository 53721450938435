.filter-item {
    padding: 5px 10px;
    margin: 0 5px 5px 0;
}

.filter-item:hover {
    cursor: pointer;
}

.table td {
    vertical-align: middle;
}

