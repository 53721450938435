.twitter-picker {
    box-shadow: none !important;
    border: none !important;
}

.hue-picker {
    margin: 12px 0;
}

.twitter-picker div:nth-child(3) {
    padding: 0 !important;
}
