.page-dropdown-item {
	min-width: 100px;
}

.nav a {
	text-decoration: none;
	color: inherit;
}

.page-dropdown-item:hover {
	cursor: pointer;
}