
.footer-container {
    max-width: 1200px;
    min-height: 175px;
}

.footer {
    background: #25292d;
    padding-top: 32px;
}

.footer-bottom {
    background: #191818;
}

.footer-bottom p {
    margin: 0 auto;
}