.slider-handle {
    left: 100%;
    background: rgb(239, 239, 239);
    height: 16px;
    width: 16px;
    margin-top: 2px;
    border: 1px solid rgb(230, 230, 230);
}

.slider-selection {
	background: #007bff
}

.slider.slider-horizontal {
    width: 100% !important;
    height: 20px;
}