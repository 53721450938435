.fade-appear,
.fade-enter {
    opacity: 0;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.5, .1, .5, 1) 150ms;
}

.fade-exit {
    opacity: 0 !important;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.5, .1, .5, 1);
}

.page {
    transition: 150ms cubic-bezier(0.5, .1, .5, 1);
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
  }

.page-wrap {
    position: absolute;
    transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}