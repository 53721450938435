body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.note-group-select-from-files {
  display: none;
}

.cc-title {
    color: #1896c6;
    margin: 0px 0 10px 0;
    padding: 0 24px;
}

.cc-subtitle {
    color: #1896c6;
    font-size: 12px;
    padding: 0 20px;
    /* text-align: center; */
    margin: 0px;
    margin-top: -12px;
}

.header {
    box-shadow: #0000001f 1px 5px 10px;
    background: #ffffff;
}

.info-bar {
    margin: 0 auto !important;
    max-width: 1200px;
}

.header-options {
    min-height: 70px;
    width: 100%;
    padding: 12px 16px;
    height: auto;
}

.logo {
    max-width:275px;
    margin: 0 auto;
}


.pulsating {
    display: block;
    cursor: pointer;
    -webkit-animation: pulsating 2s infinite;
            animation: pulsating 2s infinite;
  }
  .pulsating:hover {
    -webkit-animation: none;
            animation: none;
  }
  
  @-webkit-keyframes pulsating {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.4);;
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  @keyframes pulsating {
    0% {;
      box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.4);;
    }
    70% {
        box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
.page-dropdown-item {
	min-width: 100px;
}

.nav a {
	text-decoration: none;
	color: inherit;
}

.page-dropdown-item:hover {
	cursor: pointer;
}
.tooltip-item * {
    /* pointer-events: none; */
}

.tooltip .tooltip-inner {
    max-width: 100%
}

.page {
    transition: 150ms cubic-bezier(0.5, .1, .5, 1);
    max-width: 100%;
    height: 100%;
}

.page-wrap {
    position: absolute;
    transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.loading-container {
    text-align: center;
    margin: 0 auto;
    padding: 32px 0;
}

.not-found-container {
    text-align: center;
    margin: 0 auto;
    padding: 32px 0;
}

.content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
    height: 100%;
    box-shadow: #0000001f 1px 5px 10px;
    min-height: 100vh;
    padding-bottom: 20px;
    top: 32px;
    position: relative;
    padding: 24px;
    margin-bottom: 32px;
    background: #ffffff;
}

.edit-page {
    margin-top:-30px;
}
.empty-results {
    padding: 24px;
    text-align: center;
}

.sort-icon:hover, .button-icon:hover {
    cursor: pointer;
}
.filter-item {
    padding: 5px 10px;
    margin: 0 5px 5px 0;
}

.filter-item:hover {
    cursor: pointer;
}

.table td {
    vertical-align: middle;
}


.home-page {
	width: 100%;
	min-height: 100vh;
	background: #ffffff;
}

.home-page img {
	width: 100vw !important;
	vertical-align: text-top;
}

.home-page table, .home-page td, .home-page tr {
	max-width: 1250px !important;
	margin: 0 auto;
	border: 0px solid black;
	text-align: center;
}


.undroppable .list-group-item {
	opacity: 0.75;
	background-color: rgb(247, 185, 71) !important;
}

.dragging .list-group-item {
	opacity: 0.75;
	background-color: rgb(171, 247, 171) !important;
}

.drag-item .list-group-item {
	transition: background-color 250ms ease ;
}

.drag-item .list-group-item {
	background-color: none;
}


.icon-menu {
    max-height: 300px;
    overflow-y: scroll;
    width: 100%;
    margin-left: -105px;
}

.icon-item:hover {
    cursor: pointer;
}
.twitter-picker {
    box-shadow: none !important;
    border: none !important;
}

.hue-picker {
    margin: 12px 0;
}

.twitter-picker div:nth-child(3) {
    padding: 0 !important;
}

.slider-handle {
    left: 100%;
    background: rgb(239, 239, 239);
    height: 16px;
    width: 16px;
    margin-top: 2px;
    border: 1px solid rgb(230, 230, 230);
}

.slider-selection {
	background: #007bff
}

.slider.slider-horizontal {
    width: 100% !important;
    height: 20px;
}
.fade-appear,
.fade-enter {
    opacity: 0;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.5, .1, .5, 1) 150ms;
}

.fade-exit {
    opacity: 0 !important;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.5, .1, .5, 1);
}

.page {
    transition: 150ms cubic-bezier(0.5, .1, .5, 1);
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
  }

.page-wrap {
    position: absolute;
    transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.footer-container {
    max-width: 1200px;
    min-height: 175px;
}

.footer {
    background: #25292d;
    padding-top: 32px;
}

.footer-bottom {
    background: #191818;
}

.footer-bottom p {
    margin: 0 auto;
}
@font-face {
    font-family: 'Nasalization';
    src: 
         url('/static/fonts/nasalization.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

html, body, #root, .app {
    height: 100%;
    overflow-x: hidden;
}

body {
    background: #1896c6 no-repeat center center fixed; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #1896c6 0%,#005b7e 100%) no-repeat center center fixed; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1896c6', endColorstr='#005b7e',GradientType=0 ) no-repeat center center fixed; /* IE6-9 */
    background-size: cover;
}

.cc-font {
    font-family: 'Nasalization';
}

#page-content table {
    padding-bottom : 20px;
    border-collapse : collapse;
    display : table;
    overflow-x : auto;
    font-weight: 300;
    width:100%;
}
#page-content table tr:nth-child(odd) {
    background : #D5D5D5;
}
#page-content th {
    background : #1896c6;
    color : #ffffff;
    border : #1896c6 solid 1px;
    padding : 10px;
    font-weight: 300;
}
#page-content td {
    border : #F0F0F0 solid 1px;
    padding : 10px;
}

.fa-add-extend {
    top: -10px;
    position: relative;
    left: -5px;
    font-size: 0.5rem;
}

.user-icon {
    padding: 6px 8px;
}

.page-amount {
    max-width: 60px;
}

.large-status {
    vertical-align: middle;
    text-align: center;
    padding: 24px;
}

.large-status button {
    padding: 24px;
    margin: 0 auto;
    width: 100%;
    font-size: 1.5rem !important;

}
.login-form {
	max-width: 512px;
	margin: 0 auto;
}
